import React, { useEffect, useState } from "react";
import Headernew from "../Reusable/Main-Layput/Headernew";
import Footernew from "../Reusable/Main-Layput/Footernew";
import { CheckUserTransaction } from "../../Action/UserTransaction";
import { useDispatch, useSelector } from "react-redux";
import Loader from "./Loader";
import { Button, Input, message } from "antd";
import UserPaymentCheck from "./UserPaymentCheck";
import _ from "lodash";
import jwt from "jwt-decode";

function PaymentPage() {
  const dispatch = useDispatch();

  const { TransactionList, isLoading } = useSelector((state) => ({
    TransactionList: state?.UserTransactionReducer?.TransactionList,
    isLoading: state?.UserTransactionReducer?.isLoading,
  }));

  const userId = localStorage.getItem("_id");
  console.log(userId)

  const [groupId, setGroupId] = useState("");
  const [dfcId, setDfcId] = useState("");
  // const [userId, setUserId] = useState("6662ed90000f7139895e4b95");
  const [payableTotalAmount, setPayableTotalAmount] = useState(0);
  const [selectedTransactions, setSelectedTransactions] = useState([]);
  const [groupUserIds, setGroupUserIds] = useState([]);
  const [showResetButton, setShowResetButton] = useState(false);
  const [paymentCharges, setPaymentCharges] = useState(0);
  const [finalPayment, setFinalPayment] = useState(0);

  useEffect(() => {
    handleSearch();
  }, [dispatch]);

  useEffect(() => {
    if (TransactionList && TransactionList.length > 0) {
      setSelectedTransactions(
        TransactionList.map((transaction) =>
          transaction.user
            ? `${transaction._id}-${transaction.user._id}`
            : transaction._id
        )
      );
      setPayableTotalAmount(
        TransactionList.reduce(
          (total, transaction) => total + transaction.amount,
          0
        )
      );
      setGroupUserIds(
        TransactionList.map((transaction) => `${transaction.user._id}`)
      );
    }
  }, [TransactionList]);

  const handleSearch = () => {
    const params = {};
    if (groupId && dfcId) {
      params.internalGroupId = groupId;
      params.dfcNumber = dfcId;
      setShowResetButton(true);
    } else if (!groupId && !dfcId) {
      params.userId = userId;
      setShowResetButton(false);
    } else {
      message.error("Please provide both Group ID and DFC Number");
      return;
    }
    dispatch(CheckUserTransaction(params));
  };

  const handleReset = () => {
    window.location.reload();
  };

  const toggleTransactionSelection = (transactionId, isSearchResult) => {
    const uniqueId = isSearchResult
      ? `${transactionId._id}-${transactionId.user._id}`
      : transactionId._id;

    if (selectedTransactions.includes(uniqueId)) {
      setSelectedTransactions(
        selectedTransactions.filter((id) => id !== uniqueId)
      );
      setGroupUserIds(
        groupUserIds.filter((id) => id !== `${transactionId.user._id}`)
      );
      const transactionAmount = TransactionList.find(
        (transaction) =>
          (isSearchResult
            ? `${transaction._id}-${transaction.user._id}`
            : transaction._id) === uniqueId
      ).amount;
      setPayableTotalAmount((prevAmount) => prevAmount - transactionAmount);
    } else {
      setSelectedTransactions([...selectedTransactions, uniqueId]);
      setGroupUserIds([...groupUserIds, `${transactionId.user._id}`]);
      const transactionAmount = TransactionList.find(
        (transaction) =>
          (isSearchResult
            ? `${transaction._id}-${transaction.user._id}`
            : transaction._id) === uniqueId
      ).amount;
      setPayableTotalAmount((prevAmount) => prevAmount + transactionAmount);
    }
  };

  // useEffect( async () => {
  //   await setPaymentCharges((payableTotalAmount * 0.0225));
  //   await setFinalPayment(payableTotalAmount + (payableTotalAmount * 0.0225));
  // }, [payableTotalAmount]);

  useEffect(() => {
    setPaymentCharges(payableTotalAmount * 0.0225);
    setFinalPayment(payableTotalAmount + payableTotalAmount * 0.0225);
  });
  return (
    <>
      <Headernew />
      <div
        style={{
          padding: "20px",
          maxWidth: "800px",
          margin: "auto",
          border: "1px solid #ccc",
          borderRadius: "10px",
          marginTop: "50px",
        }}
      >
        <h2>Payment Page</h2>

        {isLoading ? (
          <Loader />
        ) : (
          <form>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>#</th>
                  <th>DFC ID</th>
                  <th>Detail</th>
                  <th>UserName</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {TransactionList && TransactionList.length > 0 ? (
                  TransactionList.map((transaction) => {
                    const uniqueId = transaction.user
                      ? `${transaction._id}-${transaction.user._id}`
                      : transaction._id;
                    return (
                      <tr key={uniqueId}>
                        <td>
                          <input
                            type="checkbox"
                            onChange={() =>
                              toggleTransactionSelection(
                                transaction,
                                Boolean(transaction.user)
                              )
                            }
                            checked={selectedTransactions.includes(uniqueId)}
                          />
                        </td>
                        <td>{transaction.dfcNumber}</td>
                        <td>Dfc Payment</td>
                        <td>{`${transaction.user.firstname} ${transaction.user.lastname}`}</td>
                        <td>{transaction.amount}</td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="5">No transactions found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </form>
        )}

        {TransactionList && TransactionList.length > 0 && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "20px",
              padding: "10px",
              borderTop: "1px solid #ccc",
            }}
          >
            <span>
              <strong>Online Payment Charges:</strong>
            </span>
            <span>
              <strong>{paymentCharges.toFixed(2)}</strong>
            </span>
          </div>
        )}

        {TransactionList && TransactionList.length > 0 && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "20px",
              padding: "10px",
              borderTop: "1px solid #ccc",
            }}
          >
            <span>
              <strong>Total Amount:</strong>
            </span>
            <span>
              <strong>{finalPayment.toFixed(2)}</strong>
            </span>
          </div>
        )}

        <UserPaymentCheck
          payableTotalAmount={finalPayment}
          userId={userId}
          groupIds={groupUserIds}
        />
      </div>
      <Footernew />
    </>
  );
}

export default PaymentPage;
