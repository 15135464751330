import {
    GET_SETTING,
    SETTING_LOADER,
    SETTING_FAIL,
} from "./ActionTypes";
import api from "../Config/Api";
import { message } from "antd";

// Fetch settings
export const getSetting = () => {
    return async (dispatch) => {
        dispatch(loader(true));
        try {
            const response = await api.get("admin/setting");
            if (response?.status === 200) {
                let data = response?.data?.message;
                dispatch({
                    type: GET_SETTING,
                    payload: data,
                });
                return Promise.resolve({ payload: data }); 
            } else {
                message.error(response?.data?.message);
                return Promise.reject(response?.data?.message); 
            }
        } catch (err) {
            console.error("Error fetching settings:", err);
            dispatch({ type: SETTING_FAIL });
            return Promise.reject(err); 
        } finally {
            dispatch(loader(false));
        }
    };
};

export const loader = (para) => {
    return {
        type: SETTING_LOADER,
        payload: para,
    };
};
