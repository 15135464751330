import {
  FETCH_DASHBOARD,
  FETCH_DASHBOARD_NULL,
  INITIALIZE_STATE,
  DASHBOARD_LOADING,
} from "./ActionTypes";
import api from "../Config/Api";
import { message } from "antd";

// death member find
export const fetchDashboardData = () => {
  return (dispatch) => {
    dispatch(loader(true));
    try {
      api.get("admin/dashboard/statistics").then(
        (response) => {
          if (response?.status === 200) {
            dispatch({
              type: FETCH_DASHBOARD,
              payload: response.data.data,
            });
          } else {
            message.error(response?.data?.message);
          }
        },
        function (error) {
          dispatch(loader(false));
          if (error.response && error.response.status === 400) {
            message.error(error?.response?.data?.message);
            dispatch({
              type: FETCH_DASHBOARD_NULL,
            });
          }
        }
      );
    } catch (err) {
      console.log("catch err", err);
    }
  };
};

export const initializeState = () => {
  return (dispatch) => {
    dispatch({
      type: INITIALIZE_STATE,
    });
  };
};

export const loader = (para) => {
  return {
    type: DASHBOARD_LOADING,
    payload: para,
  };
};
