import { ADMIN_LOGIN_SUCCESS, ADMIN_LOGOUT } from "./ActionTypes";
import api from "../Config/Api";
import { message } from "antd";
import jwt from "jwt-decode";
import moment from "moment";

export const LoginCheck = (email, password, navigate, tokenRecaptcha) => {
  //
  return (dispatch) => {
    const credentials = {
      email,
      password,
      reCAPTCHA: tokenRecaptcha,
    };

    try {
      api.post("admin/signin", credentials).then(
        (response) => {
          let token = response.data.data.adminToken;

          if (response.status === 200) {
            dispatch({
              type: ADMIN_LOGIN_SUCCESS,
              payload: { tokenState: token, isLoggedInAdminState: true },
            });
            localStorage.setItem("Bearer", JSON.stringify(token));
            localStorage.setItem("USER_NAME", email);
            SaveDateTimeInToken(token);
            navigate("/dashboard");
          } else {
            errorMessageConfig();
          }
        },
        function (error) {
          if (
            (error.response && error.response.status === 401) ||
            (error.response && error.response.status === 400)
          ) {
            message.error(error.response.data.message);
          } else {
            errorMessageConfig();
          }
        }
      );
    } catch (err) {
      errorMessageConfig();
    }
  };
};

function errorMessageConfig() {
  message.error("Something Went Wrong");
}

export const LogoutAdmin = (navigate) => {
  return (dispatch) => {
    dispatch({
      type: ADMIN_LOGOUT,
      payload: { isLoggedInAdminState: false },
    });

    localStorage.clear();
    navigate("/login");
  };
};

export const SaveDateTimeInToken = (token) => {
  let user = jwt(token);
  let expiryDate = new Date(user.exp);

  let DateTimeToken = moment(new Date(expiryDate)).format(
    "YYYY-MM-DD HH:mm:ss"
  );
  localStorage.setItem("DateTimeToken", DateTimeToken);
};
