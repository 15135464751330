/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { Helmet } from "react-helmet";
// import styles from "../style.module.scss";
import Headernew from "../../Reusable/Main-Layput/Headernew";
// import "../custom.css";
import Footernew from "../../Reusable/Main-Layput/Footernew";
import styles from "./style.module.scss";

function Privacy() {
  return (
    <div>
      <Headernew />
      <Helmet title="Privacy Policy" />
      <div className={`${styles.cmsContentContianer}`}>
        <section className={`${styles.ctfHire}`}>
          <div className="container-fluid overview-page-container">
            <div className={`${styles.ctfhireoffer}`}>
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <h2 className="ctf-hire-offer-category cms-page-heading">
                    Privacy Policy
                  </h2>
                  <div>
                    <p>
                      Thank you for visiting the શ્રી સમસ્ત દશા શ્રી માળી
                      સુખડીયા સુરક્ષા ટ્રસ્ટ, અમદાવાદ website. Your privacy is
                      important to us. To better protect your privacy, we
                      provide this notice explaining our online information
                      practices and the choices you can make about the way your
                      information is collected and used on this website.
                    </p>
                    <p>
                      We will not intentionally disclose such information to
                      third parties for commercial purposes.
                    </p>
                    <p>
                      The Information We Collect On sections of this site, you
                      can donate money, contact us. The types of personally
                      identifiable information that may be collected at these
                      pages include: name, address, e-mail address, telephone
                      number, credit card information, and information about
                      your interests in and use of various services.
                    </p>
                    <p>
                      <h3>How We Use the Information</h3>
                      We may use the information you provide about yourself to
                      fulfil your requests to donate towards our fundraising
                      projects and participate in our events, to respond to your
                      inquiries to offer you information that we believe may be
                      of interest to you.
                    </p>

                    <p>
                      We sometimes use this information to communicate with you,
                      to fulfil a request by you for an online newsletter, or to
                      contact you about the progress of projects you support and
                      your relationship with us. We sometimes use the
                      non-personally identifiable information that we collect to
                      improve the design and content of our site and to enable
                      us to personalise your Internet experience. We also may
                      use this information in the aggregate to analyse site
                      usage.{" "}
                    </p>

                    <p>
                      {" "}
                      We may disclose personally identifiable information in
                      response to legal process, for example, in response to a
                      court order or a subpoena. We also may disclose such
                      information in response to a law enforcement agency’s
                      request, or where we believe it is necessary to
                      investigate, prevent, or take action regarding illegal
                      activities, suspected fraud, situations involving
                      potential threats to the physical safety of any person,
                      violations of our terms of use, or as otherwise required
                      by law.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footernew />
    </div>
  );
}

export default Privacy;
