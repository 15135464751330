import React, { useState, useRef, useEffect } from "react";
import { Table, message, Space, Button, Input, Tooltip, Tag, Popconfirm } from "antd";
import { Link } from "react-router-dom";
import Loader from "../../Component/Reusable/Loader/Loader";
import moment from 'moment';
import api from "../../Config/Api";
import {
  EyeOutlined,
  SearchOutlined,
  DownloadOutlined,
  QuestionCircleOutlined,
  DeleteOutlined,
  EditOutlined
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { useSelector, useDispatch } from "react-redux";
import { TransactionListAction } from "../../Action/Transaction";
import SiteLayout from "../Reusable/Layout/SiteLayout";
import "../Members/userstyle.scss";
import "../Members/style.css";

const Transactions = () => {
  const TransactionList = useSelector(state => state?.TransactionReducer?.TransactionList);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [pdfLoader, setPdfLoader] = useState(false);
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchTransactions = async () => {
      setLoading(true);
      await dispatch(TransactionListAction());
      setLoading(false);
    };
    fetchTransactions();
  }, [dispatch]);

  const getColumnSearchProps = (dataIndex, title) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${title}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 112 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 112 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]?.toString()?.toLowerCase()?.includes(value?.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const dataSource = TransactionList?.map((data, index) => ({
    key: index,
    id: data?._id,
    amount: data?.amount,
    lateFees: data?.lateFees,
    paymentMethod: data?.paymentMethod,
    paymentDate: moment(data?.paymentDate).format("DD MMMM YYYY"),
    status: data?.status,
    receiptNumber: data?.receiptNumber,
  }));

  const generatePdfForTransaction = async (txnId) => {
    setPdfLoader(true);
    try {
      const response = await api.post(
        "/admin/downloadPdfForTransaction",
        { txnId },
        {
          responseType: "blob",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const blob = new Blob([response.data], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `Transaction_${txnId}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error occurred while downloading the PDF file:", error);
      message.error('Failed to download PDF');
    }
    setPdfLoader(false);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const handleDelete = async (txnId) => {
    try {
      const response = await api.delete(`/admin/deleteTransaction?txnId=${txnId}`);
      if (response.status === 200) {
        message.success('Transaction deleted successfully');      
        dispatch(TransactionListAction());
      } else {
        message.error('Failed to delete transaction');
      }
    } catch (error) {
      message.error('Transaction made through "Phonepe" is not Deletable');
      console.error("Error:", error);
    }
  };

  const columns = [
    {
      title: "Total Amount",
      dataIndex: "amount",
      key: "amount",
      ...getColumnSearchProps("amount", "Amount"),
    },
    {
      title: "Late Fees",
      dataIndex: "lateFees",
      key: "lateFees",
      ...getColumnSearchProps("lateFees", "Late Fees"),
    },
    {
      title: "Payment Method",
      dataIndex: "paymentMethod",
      key: "paymentMethod",
      ...getColumnSearchProps("paymentMethod", "Payment Method"),
    },
    {
      title: "Receipt No.",
      dataIndex: "receiptNumber",
      key: "receiptNumber",
      ...getColumnSearchProps("receiptNumber", "Receipt No."),
    },
    {
      title: "Payment Date",
      dataIndex: "paymentDate",
      key: "paymentDate",
      ...getColumnSearchProps("paymentDate", "Payment Date"),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text, row) => (
        <Space size="middle">
          <Tag
            color={
              (["SUCCESS", "success"].includes(row?.status) && "blue") ||
              (["FAILED", "failed"].includes(row?.status) && "red")
            }
          >
            {row?.status}
          </Tag>
        </Space>
      ),
    },
    {
      title: "Operation",
      render: (text, record) => (
        <Space size="middle">
          <Tooltip title="Download">
            <Button
              style={{ background: "black", border: "black" }}
              type="primary"
              icon={<DownloadOutlined />}
              onClick={() => generatePdfForTransaction(record?.id)}
            />
          </Tooltip>

          <Tooltip title="View">
            <Link to={`/transactionsdetails/${record?.id}`}>
              <Button type="primary" icon={<EyeOutlined />} />
            </Link>
          </Tooltip>

          <Tooltip title="Edit">
          <Link to={`/edittransaction/${record?.id}`}>
            <Button
              // style={{ background: "black", border: "black" }}
              type="primary"
              icon={<EditOutlined />}
              disabled={record.paymentMethod === "PHONEPE"} 
            />
          </Link>
        </Tooltip>
          <Popconfirm
            title="Are you sure you want to Delete this Transaction?"
            onConfirm={() => handleDelete(record?.id)}
            icon={<QuestionCircleOutlined style={{ color: "red" }} />}
          >
            <Tooltip title="Delete">
              <Button danger type="primary" icon={<DeleteOutlined />} />
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <SiteLayout>
      {loading && <Loader />}
      <div style={{ padding: "30px" }} className="checkout-form-container gig-sure-card-wrapper">
        <div className="wrapper_twoDiv">
          <Space style={{ marginBottom: 18 }}>
            <h3 className="start">Transactions List</h3>
          </Space>

          <Table dataSource={dataSource} columns={columns} pagination={false} />


          {/* PDF Loader */}
          {pdfLoader && <Loader message="Generating PDF..." />}
        </div>
      </div>
    </SiteLayout>
  );
};

export default Transactions;
