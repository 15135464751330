/* eslint-disable react/no-unescaped-entities */
import Headernew from "../../Reusable/Main-Layput/Headernew";
import styles from "./style.module.scss";
import { Form, Input, Button, notification } from "antd";
import { Link } from "react-router-dom";

/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { Helmet } from "react-helmet";
// import styles from "../style.module.scss";

// import "../custom.css";
import Footernew from "../../Reusable/Main-Layput/Footernew";

const Contact = (props) => {
  const { TextArea } = Input;
  const FormItem = Form.Item;
  const handleSubmit = (event) => {
    event.preventDefault();
    // const { form, dispatch } = props
    // const { captchaKey } = state
    // form.validateFieldsAndScroll((error, values) => {
    //   if (!error && captchaKey) {
    //     // if (captchaKey) {
    //     //   values.captchaKey = captchaKey
    //     // }
    //     dispatch({
    //       type: 'user/SUBMIT_CONTACT_QUERY',
    //       payload: values,
    //     })
    //   } else if (!error && captchaKey === '') {
    //     notification.error({
    //       message: 'Error',
    //       description: 'Please verify that you are not a robot!',
    //     })
    //   }
    // })
  };

  return (
    <div
      style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
    >
      <Headernew />
      <Helmet title="Contact Us" />

      <div
        className={styles.cmsContentContainer}
        style={{ flexGrow: 1, padding: "50px 15px" }}
      >
        <section className={styles.ctfHire}>
          <div className="container-fluid cms-page-container">
            <div className={styles.ctfHireOffer}>
              <div className="row justify-content-center">
                <div className="col-lg-8 col-md-10">
                  <h2 className="cms-page-heading">Contact Us</h2>
                  <Form
                    className="login-form custom-form"
                    onSubmit={handleSubmit}
                  >
                    <div className="row">
                      <div className="col-lg-6">
                        <FormItem label={<span>First Name</span>}>
                          <Input />
                        </FormItem>
                      </div>
                      <div className="col-lg-6">
                        <FormItem label={<span>Last Name</span>}>
                          <Input type="text" />
                        </FormItem>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6">
                        <FormItem label={<span>Email Address</span>}>
                          <Input />
                        </FormItem>
                      </div>
                      <div className="col-lg-6">
                        <FormItem label={<span>Phone Number </span>}>
                          <Input />
                        </FormItem>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-12">
                        <FormItem label="Message">
                          <TextArea rows={4} />
                        </FormItem>
                      </div>
                    </div>

                    <div className="form-actions remove-buton-css">
                      <Button
                        style={{ width: 180 }}
                        htmlType="submit"
                        className="btn btn-primary mr-3"
                        // loading={contactSubmitLoading}
                      >
                        Submit
                      </Button>
                      <p className="mt-3 mb-5">
                        * We don't share your information with anyone. Check out
                        our{" "}
                        <Link
                          to="/terms-conditions"
                          target="_blank"
                          className={styles.linkHighligher}
                        >
                          Terms & Conditions
                        </Link>{" "}
                        for more details.
                      </p>
                      <h2>Address</h2>
                      <p>
                        A/2 Padamprabhu Society,
                        <br />
                        Near Jethabhai Park Bus Stand,
                        <br />
                        Narayannagar Road, Paldi,
                        <br />
                        Ahmedabad-7
                      </p>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footernew />
    </div>
  );
};

export default Contact;

// function Contact() {
//   return (
//     <div>
//       <div class="container">
//         <div class=" text-center mt-5 ">

//           <h1 >Bootstrap Contact Form</h1>


//         </div>


//         <div class="row ">
//           <div class="col-lg-7 mx-auto">
//             <div class="card mt-2 mx-auto p-4 bg-light">
//               <div class="card-body bg-light">

//                 <div class="container">
//                   <form id="contact-form" role="form">



//                     <div class="controls">

//                       <div class="row">
//                         <div class="col-md-6">
//                           <div class="form-group">
//                             <label for="form_name">Firstname *</label>
//                             <input id="form_name" type="text" name="name" class="form-control" placeholder="Please enter your firstname *" required="required" data-error="Firstname is required." />

//                           </div>
//                         </div>
//                         <div class="col-md-6">
//                           <div class="form-group">
//                             <label for="form_lastname">Lastname *</label>
//                             <input id="form_lastname" type="text" name="surname" class="form-control" placeholder="Please enter your lastname *" required="required" data-error="Lastname is required." />
//                           </div>
//                         </div>
//                       </div>
//                       <div class="row">
//                         <div class="col-md-6">
//                           <div class="form-group">
//                             <label for="form_email">Email *</label>
//                             <input id="form_email" type="email" name="email" class="form-control" placeholder="Please enter your email *" required="required" data-error="Valid email is required." />

//                           </div>
//                         </div>
//                         <div class="col-md-6">
//                           <div class="form-group">
//                             <label for="form_need">Please specify your need *</label>
//                             <select id="form_need" name="need" class="form-control" required="required" data-error="Please specify your need.">
//                               <option value="" selected disabled>--Select Your Issue--</option>
//                               <option >Request Invoice for order</option>
//                               <option >Request order status</option>
//                               <option >Haven't received cashback yet</option>
//                               <option >Other</option>
//                             </select>

//                           </div>
//                         </div>
//                       </div>
//                       <div class="row">
//                         <div class="col-md-12">
//                           <div class="form-group">
//                             <label for="form_message">Message *</label>
//                             <textarea id="form_message" name="message" class="form-control" placeholder="Write your message here." rows="4" required="required" data-error="Please, leave us a message."></textarea
//                             >
//                           </div>

//                         </div>


//                         <div class="col-md-12">

//                           <input type="submit" class="btn btn-success btn-send  pt-2 btn-block
//                         " value="Send Message" />

//                         </div>

//                       </div>


//                     </div>
//                   </form>
//                 </div>
//               </div>


//             </div>


//           </div>


//         </div>
//       </div>
//     </div>
//   )
// }

// export default Contact