import { DFC_FEES_ADD, LOADER, ADD_MEMBER_LOADING ,GET_DFC_DETAIL} from "./ActionTypes";
import api from "../Config/Api";
import { message } from "antd";

//add fees
export const AddDfcFees = (payload) => {

    return (dispatch) => {
        dispatch({
            type: DFC_FEES_ADD,
            payload
        });
    }

    // When API is Working.....

    // return (dispatch) => {
    //     dispatch({
    //         type: ADD_MEMBER_LOADING,
    //     });
    //     try {
    //         console.log("Action payload------> ", payload);
    //         api.post("dfc/fee/details", payload).then(
    //             (response) => {
    //                 if (response?.status === 201) {
    //                     message.success(response?.data?.message);
    //                     dispatch({
    //                         type: DFC_FEES_ADD,
    //                         payload: response,
    //                     });
    //                 }
    //             },
    //             function (error) {
    //                 if (error.response && error.response.status === 400) {
    //                     message.error(error?.response?.data?.message);
    //                 }
    //                 // dispatch({
    //                 //   type: ADD_MEMBER_ERROR,
    //                 //   payload: error,
    //                 // });
    //             }
    //         );
    //     } catch (err) {
    //         console.log("catch err");
    //         errorMessageConfig(dispatch);
    //     }
    // };
};

// export const getDFCDetail = (dfcNumber) => {
//     return (dispatch) => {
//       dispatch(loader(true));
//       try {
//         api.get(`admin/fetchDfcDetails?dfcNumber=${dfcNumber}`).then(
//           (response) => {
//             if (response?.status === 200) {
//               dispatch({
//                 type: GET_DFC_DETAIL,
//                 payload: response.data.message,
//               });
//               dispatch(loader(false));
//             } else {
//               message.error(response?.data?.message);
//             }
//           },
//           function (error) {
//             if (error.response && error.response.status === 400) {
//               message.error(error?.response?.data?.message);
//             }
//           }
//         );
//       } catch (err) {
//         console.log("catch err", err);
//         dispatch(loader(false));
//       }
//     };
//   };



function errorMessageConfig(dispatch) {
    dispatch(loader(false));
    message.error("Something Went Wrong");
}

export const loader = (para) => {
    return {
        type: LOADER,
        payload: para,
    };
};
