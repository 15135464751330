/* eslint-disable no-unused-vars */
import React, { useRef, useEffect, useState } from "react";
import SiteLayout from "../../Component/Reusable/Layout/SiteLayout";
import { connect } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import "../Members/style.css";
import { useDispatch } from "react-redux";
import api from "../../Config/Api";
import moment from "moment";
import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
} from "@mui/material";
import {
  Button,
  Space,
  DatePicker,
  Radio,
  Form,
  Input,
  Row,
  Col,
  Typography,
  Select,
  message,
  Spin,
} from "antd";
// import { Box, Typography, Button, Input, CircularProgress,  } from '@mui/material';
import { getSetting } from "../../Action/GetSetting";

import { AddDfcFees } from "../../Action/DfcFees";
const { Title } = Typography;
// Action
const mapDispatch = (dispatch) => {
  return {
    // fees action call
    addFess: (payload) => dispatch(AddDfcFees(payload)),
  };
};

const mapState = (props) => ({
  SelectLoader: props?.MemberReducer?.isloading,
  // ResponseCode: props?.MemberReducer?.ResponseCode,
  // error: props?.MemberReducer?.error,
  // errorDetails: props?.MemberReducer?.errorDetails,
  success: props?.MemberReducer?.success,
  successDetails: props?.MemberReducer?.successDetails,
  MemberDetails: props?.MemberReducer?.MemberDetails,
});

function AddFees(store) {
  const [form] = Form.useForm();
  const formRef = useRef();
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const dfcdata = useSelector((state) => state.FeesReducer);
  const [receiptStartNo, setReceiptStartNo] = useState(null); // To store receipt start number
  const [currentReceiptNo, setCurrentReceiptNo] = useState(null); // To manage current receipt number

  // const setting = useSelector((state) => state.setting ? state.setting.data : {});
  // console.log("setting",setting)
  // const receiptStartNumber = setting?.Receipt_start_number || 'N/A';
  // // console.log("receiptStartNumber",receiptStartNumber)

  // useEffect(() => {
  //     dispatch(getSetting());
  // }, [dispatch]);

  const setting = useSelector((state) =>
    state.setting ? state.setting.data : {}
  );

  useEffect(() => {
    dispatch(getSetting());
  }, [dispatch]);

  // Extract the receipt start number from the setting data
  const receiptStartNumber = setting?.Receipt_start_number || "N/A";

  // Edit
  useEffect(() => {
    id && store?.SingalMemberDetail(id);
    // console.log("data--------->", dfcdata);
    // eslint-disable-next-line
  }, [id, dfcdata]);

  useEffect(() => {
    form.setFieldsValue({
      PaymentMethod: "CASH",
      // by default payment method is cash in payload......
    });
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (store?.success && store?.successDetails?.data?.message) {
      console.log("here-succc");
      form.resetFields();
      store.resetState();
    }

    // eslint-disable-next-line
  }, [store?.success && store?.successDetails?.data?.message]);

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleFormSubmit = async (params) => {
    try {
      const payload = {
        groupId: params?.GroupId,
        amount: params?.Amount,
        paymentMethod: params?.PaymentMethod,
        userId: params?.UserId,
        dfcNumber: params?.DfcNumber,
        receiptNo: currentReceiptNo, // Use the fetched receipt number
        receiptDate: params?.ReceiptDate,
        membersFees: params?.MembersFees,
      };

      if (params?.PaymentMethod === "CHEQUE") {
        payload.paymentDetails = {
          bank: params?.BankName,
          branch: params?.BranchName,
          chequeNo: params?.ChequeNo,
          chequeDate: params?.ChequeDate,
        };
      }

      const response = await api.post("/admin/transaction-details", payload, {
        headers: { "Content-Type": "application/json" },
      });

      if (response.data.success) {
        message.success("Transaction submitted successfully!");
        form.resetFields();
      } else {
        message.error(`Failed to submit: ${response.data.message}`);
      }
    } catch (error) {
      message.error(`An error occurred: ${error.message}`);
    }
  };

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await dispatch(getSetting());
        if (response?.payload) {
          const receiptStartNumber = response.payload.Receipt_start_number;
          setCurrentReceiptNo(receiptStartNumber); // Set the current receipt number
          form.setFieldsValue({ receiptNumber: receiptStartNumber }); // Set receipt number in form
        }
      } catch (err) {
        message.error("Failed to fetch settings.");
        console.error(err);
      }
    };

    fetchSettings();
  }, [dispatch, form]);

  // Automatically set the receipt number in the form when it changes
  useEffect(() => {
    if (currentReceiptNo !== null) {
      form.setFieldsValue({ receiptNo: currentReceiptNo });
    }
  }, [currentReceiptNo, form]);
  // Automatically set the receipt number in the form

  // set member details auto when group id is not null
  const setmembervalue = (value) => {
    if (value) {
      form.setFieldsValue({
        MemberId1: "100",
        Member1: "member 1",
        City1: "Rajkot",
        Fees1: 100,
        lateFees1: 0,
        MemberId2: "101",
        Member2: "member 2",
        City2: "Gondal",
        Fees2: 100,
        lateFees2: 50,
      });
    } else {
      form.setFieldsValue({
        MemberId1: null,
        member: null,
        City1: null,
        Fees1: null,
        lateFees1: null,
        MemberId2: null,
        Member2: null,
        City2: null,
        Fees2: null,
        lateFees2: null,
      });
    }
  };

  const [value, setValue] = useState("CASH");

  // this is dfc fees code
  const [dfcNumber, setDfcNumber] = useState("");
  const [dfcDetails, setDfcDetails] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    if (!dfcNumber) {
      message.error("DFC number is required");
      return;
    }

    if (!/^\d+$/.test(dfcNumber)) {
      message.error("Please insert a valid DFC number");
      return;
    }

    setLoading(true);

    try {
      const response = await api.get(
        `admin/fetchDfcDetails?dfcNumber=${dfcNumber}`
      );

      if (response.status === 200) {
        setDfcDetails(response.data.data);
        message.success("Data fetched successfully");
      } else {
        message.error(response.data.message || "Failed to fetch details");
      }
    } catch (error) {
      message.error(
        error.response?.data?.message || "Please insert valid Dfc No."
      );
    } finally {
      setLoading(false);
    }
  };

  const [groupData, setGroupData] = useState("");
  const [members, setMembers] = useState([]);
  const [totalFees, setTotalFees] = useState(0);
  const [totallateFees, setTotallateFees] = useState(0);
  const [combinedTotal, setCombinedTotal] = useState(0);
  const [userId, setUserId] = useState(""); // This will give you the first member of the group
  const [memberDetails, setMemberDetails] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState("CASH");
  const [cashDetails, setCashDetails] = useState({
    receiptNo: "",
    receiptDate: null,
  });
  const [chequeDetails, setChequeDetails] = useState({
    bankName: "",
    branchName: "",
    chequeNo: "",
    chequeDate: null,
    receiptNo: "",
    receiptDate: null,
  });
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  // const [dfcNumber, setDfcNumber] = useState(""); // Add this state to manage dfcNumber

  useEffect(() => {
    const isDisabled =
      !dfcNumber ||
      !groupData ||
      !combinedTotal ||
      !paymentMethod ||
      (paymentMethod === "CASH" &&
        (cashDetails.receiptNo || !cashDetails.receiptDate)) ||
      (paymentMethod === "CHEQUE" &&
        (chequeDetails.receiptNo ||
          !chequeDetails.bankName ||
          !chequeDetails.branchName ||
          !chequeDetails.chequeNo ||
          !chequeDetails.chequeDate ||
          chequeDetails.receiptDate));

    setIsSubmitDisabled(isDisabled);
  }, [
    dfcNumber,
    groupData,
    combinedTotal,
    paymentMethod,
    cashDetails,
    chequeDetails,
  ]);

  const fetchGroupData = async () => {
    if (!groupData) {
      message.error("Please enter a Group ID");
      return;
    }

    try {
      const response = await api.get("/admin/fetchGroupDetails", {
        params: { internalGroupId: groupData },
      });

      if (response.data.success) {
        const fetchedMembers = response.data.data.members;

        setMembers(fetchedMembers);

        if (fetchedMembers.length > 0) {
          setUserId(fetchedMembers[0].memberNo);
        }

        const memberDetailsArray = fetchedMembers.map((member) => ({
          _id: member._id,
          memberId: member.memberNo,
          fees: member.fees || 0,
          lateFees: member.lateFees || 0,
        }));

        setMemberDetails(memberDetailsArray);

        const fields = fetchedMembers.reduce((acc, member, index) => {
          acc[`MemberId_${index}`] = member.memberNo;
          acc[`Member_${index}`] = member.memberName;
          acc[`City_${index}`] = member.city;
          acc[`Fees_${index}`] = member.fees || 0;
          acc[`LateFees_${index}`] = member.lateFees || 0;
          return acc;
        }, {});

        // Set the member details in the form
        form.setFieldsValue(fields);

        // Generate the receipt number after fetching group data

        message.success("Data fetched successfully");
      } else {
        message.error("Failed to fetch group data");
      }
    } catch (error) {
      console.error("API error:", error);
      message.error("Failed to fetch group data. Please try again.");
    }
  };

  const onGroupDataChange = (e) => {
    setGroupData(e.target.value);
  };

  const handleSubmit1 = async () => {
    await fetchGroupData();
  };

  const updateTotals = (updatedDetails) => {
    let newTotalFees = 0;
    let newTotallateFees = 0;

    updatedDetails.forEach(({ fees, lateFees }) => {
      newTotalFees += fees || 0;
      newTotallateFees += lateFees || 0;
    });

    const newCombinedTotal = newTotalFees + newTotallateFees;

    setTotalFees(newTotalFees);
    setTotallateFees(newTotallateFees);
    setCombinedTotal(newCombinedTotal);
  };

  const handleInputChange = (index, fieldType) => (e) => {
    const value = parseFloat(e.target.value) || 0;

    setMemberDetails((prevDetails) => {
      const updatedDetails = [...prevDetails];
      updatedDetails[index] = {
        ...updatedDetails[index],
        [fieldType]: value,
      };

      updateTotals(updatedDetails);

      return updatedDetails;
    });
  };

  const handleCashDetailsChange = (e) => {
    const { name, value } = e.target;
    setCashDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleCashDateChange = (date, dateString) => {
    setCashDetails((prevDetails) => ({
      ...prevDetails,
      receiptDate: dateString,
    }));
  };

  const handleChequeDetailsChange = (e) => {
    const { name, value } = e.target;
    setChequeDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleChequeDateChange = (date, dateString) => {
    setChequeDetails((prevDetails) => ({
      ...prevDetails,
      chequeDate: dateString,
    }));
  };

  const Finalsubmit = async () => {
    try {
      const payload = {
        groupId: groupData,
        amount: combinedTotal,
        paymentMethod: paymentMethod,
        dfcNumber: dfcNumber,
        membersFees: memberDetails,
        receiptNo: cashDetails.receiptNo, // The generated receipt number is included in the payload
        receiptDate: cashDetails.receiptDate,
        ...(paymentMethod === "CASH" && { cashDetails }),
        ...(paymentMethod === "CHEQUE" && { paymentDetails: chequeDetails }),
      };

      const response = await api.post("/admin/transaction-details", payload, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.data.success) {
        message.success("Transaction details submitted successfully!");

        // Increment the receipt number for the next transaction (if needed)
        setCurrentReceiptNo((prevReceiptNo) => prevReceiptNo + 1);

        // Reset form fields
        form.resetFields();
      } else {
        message.error(`Failed to submit: ${response.data.message}`);
      }
    } catch (error) {
      message.error(
        `An error occurred while submitting the transaction details. ${error.message}`
      );
    }
  };

  console.log(
    groupData,
    paymentMethod,
    combinedTotal,
    members,
    cashDetails,
    chequeDetails,
    dfcNumber,
    userId,
    memberDetails
  );

  return (
    <SiteLayout>
      <div className="headingDiv">
        <h2 style={{ textAlign: "center", margin: "0px" }}>DFC Fees</h2>
        {/* <p>Receipt  Number: {receiptStartNumber}
        </p> */}
        {/* <p><strong>Current Receipt Number: </strong>{currentReceiptNo !== null ? currentReceiptNo : 'Fetching...'}</p> */}
      </div>

      <div className="subscription-container center-content">
        <Row gutter={2}>
          <Col
            //changes start
            xxl={20}
            xl={20}
            lg={24}
            md={24}
            sm={24}
            xs={24}
            style={{ margin: "0 auto" }}
            //changes end
          >
            <div
              style={{ padding: "30px" }}
              className="checkout-form-container gig-sure-card-wrapper"
            >
              <Form
                scrollToFirstError={true}
                onFinish={handleFormSubmit}
                onFinishFailed={onFinishFailed}
                layout="vertical"
                form={form}
                name="dfc"
                autoComplete="off"
                ref={formRef}
              >
                <Row className="mt-1">
                  <Col span={24}>
                    <Title level={4}>DFC Details</Title>
                  </Col>
                </Row>
                {/* Dfc numer part */}
                <Box component="form" sx={{ mt: 3 }}>
                  <Row gutter={10}>
                    <Col xl={8} lg={8} md={10} sm={24} xs={24}>
                      <Row gutter={10} align="middle">
                        <Col span={16}>
                          <Form.Item
                            name="DFC Number"
                            label="DFC Number"
                            rules={[
                              {
                                pattern: /^(?:\d*)$/,
                                message: "Please insert a valid Group ID",
                              },
                            ]}
                          >
                            <Input
                              className="form-input-border"
                              autoComplete="off"
                              placeholder="DFC Number"
                              value={dfcNumber}
                              onChange={(e) => setDfcNumber(e.target.value)}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Button
                            // type="primary"
                            variant="contained"
                            color="primary"
                            className="btn"
                            disabled={loading}
                            onClick={handleSubmit}
                            sx={{ width: "100%", py: 1, height: "40px" }}
                          >
                            {loading ? (
                              <CircularProgress size={24} />
                            ) : (
                              "Search"
                            )}
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  {dfcDetails && (
                    <Box mt={1}>
                      <Typography variant="h6" mb={2}>
                        DFC Details:
                      </Typography>
                      {dfcDetails.length > 0 ? (
                        <TableContainer component={Paper}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>Member No</TableCell>
                                <TableCell>Member Name</TableCell>
                                <TableCell>Date of Death</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {dfcDetails.map((detail, index) => (
                                <TableRow key={index}>
                                  <TableCell>{detail.memberNo}</TableCell>
                                  <TableCell>{detail.memberName}</TableCell>
                                  <TableCell>
                                    {new Date(
                                      detail.dateOfDeath
                                    ).toLocaleDateString()}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      ) : (
                        <Typography variant="body2">
                          No details available for this DFC number.
                        </Typography>
                      )}
                    </Box>
                  )}
                </Box>

                {/* Group id part */}
                <Form form={form}>
                  <Row gutter={10}>
                    <Col xl={8} lg={8} md={10} sm={24} xs={24}>
                      <Row gutter={10} align="middle">
                        <Col span={16}>
                          <Form.Item
                            name="Group"
                            label="Group #"
                            rules={[
                              {
                                pattern: /^(?:\d*)$/,
                                message: "Please insert a valid Group ID",
                              },
                            ]}
                          >
                            <Input
                              className="form-input-border"
                              autoComplete="off"
                              placeholder="Group ID"
                              value={groupData}
                              onChange={onGroupDataChange}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Button
                            // type="primary"
                            variant="contained"
                            color="primary"
                            className="btn"
                            onClick={handleSubmit1}
                            sx={{ width: "100%", py: 1, height: "40px" }}
                          >
                            Search
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  {members.map((member, index) => (
                    <Row gutter={10} key={index}>
                      <Col xl={4} lg={6} md={8} sm={24} xs={24}>
                        <Form.Item
                          name={`MemberId_${index}`}
                          label="Member Id #"
                          className="field-with-prefix"
                        >
                          <Input
                            placeholder="Member Id"
                            disabled={true}
                            style={{ color: "#808080" }}
                          />
                        </Form.Item>
                      </Col>

                      <Col xl={8} lg={12} md={16} sm={24} xs={24}>
                        <Form.Item
                          name={`Member_${index}`}
                          label="Member #"
                          className="field-with-prefix"
                        >
                          <Input
                            placeholder="Member"
                            disabled={true}
                            style={{ color: "#808080" }}
                          />
                        </Form.Item>
                      </Col>

                      <Col xl={4} lg={6} md={8} sm={24} xs={24}>
                        <Form.Item
                          name={`City_${index}`}
                          label="City #"
                          className="field-with-prefix"
                        >
                          <Input
                            placeholder="City"
                            disabled={true}
                            style={{ color: "#808080" }}
                          />
                        </Form.Item>
                      </Col>

                      <Col xl={4} lg={12} md={8} sm={24} xs={24}>
                        <Form.Item
                          name={`Fees_${index}`}
                          label="Fees #"
                          className="field-with-prefix"
                          rules={[
                            {
                              pattern: /^(?:\d*)$/,
                              message: "Please insert valid Fees",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Fees"
                            name={`Fees_${index}`}
                            value={memberDetails[index]?.fees || 0} // Bind the value from the state
                            onChange={handleInputChange(index, "fees")} // Pass 'fees' to the handler
                          />
                        </Form.Item>
                      </Col>

                      <Col xl={4} lg={12} md={8} sm={24} xs={24}>
                        <Form.Item
                          name={`lateFees_${index}`}
                          label="lateFees #"
                          className="field-with-prefix"
                          rules={[
                            {
                              pattern: /^(?:\d*)$/,
                              message: "Please insert valid lateFees",
                            },
                          ]}
                        >
                          <Input
                            placeholder="lateFees"
                            name={`lateFees_${index}`}
                            value={memberDetails[index]?.lateFees || 0} // Bind the value from the state
                            onChange={handleInputChange(index, "lateFees")} // Pass 'lateFees' to the handler
                          />
                        </Form.Item>
                      </Col>

                      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                        <hr style={{ marginBottom: "20px" }} />
                      </Col>
                    </Row>
                  ))}
                </Form>
                {/* Group id part end here  */}

                {/* the total of fees and lateFees and there total also */}
                {members.length > 0 && (
                  <div>
                    <h4>Total Amount</h4>
                    {console.log(totallateFees, totalFees)}

                    {/* Display totals */}
                    <Row gutter={10}>
                      {/* <Col xl={4} lg={6} md={8} sm={24} xs={24}>
                        <Form.Item label="Total Fees">
                          <Input
                            value={totalFees.toFixed(2)}
                            disabled={true}
                            style={{ color: "#808080" }}
                          />
                        </Form.Item>
                      </Col>

                      <Col xl={4} lg={6} md={8} sm={24} xs={24}>
                        <Form.Item label="Total lateFees">
                          <Input
                            value={totallateFees.toFixed(2)}
                            disabled={true}
                            style={{ color: "#808080" }}
                          />
                        </Form.Item>
                      </Col> */}

                      <Col xl={4} lg={6} md={8} sm={24} xs={24}>
                        <Form.Item label="Combined Total">
                          <Input
                            value={combinedTotal.toFixed(2)}
                            disabled={true}
                            style={{ color: "#808080" }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                )}
                {/* ther total ends here */}

                {/*  payment */}
                <Form>
                  <Row className="mt-1">
                    <Col span={24}>
                      <Title level={4}>Payment</Title>
                    </Col>
                  </Row>

                  {/* radio button */}
                  <Form.Item
                    name="PaymentMethod"
                    label="Payment Method #"
                    initialValue={paymentMethod}
                  >
                    <Radio.Group
                      value={paymentMethod}
                      onChange={(e) => setPaymentMethod(e.target.value)}
                    >
                      <Radio value="CASH">Cash</Radio>
                      <Radio value="CHEQUE">Cheque</Radio>
                    </Radio.Group>
                  </Form.Item>

                  {/* input for cheque */}
                  {paymentMethod === "CHEQUE" && (
                    <Row gutter={10}>
                      <Col xl={8} lg={12} md={12} sm={24} xs={24}>
                        <Form.Item
                          name="BankName"
                          label="Bank Name #"
                          className="field-with-prefix"
                          rules={[
                            {
                              required: true,
                              message: "Bank Name is required",
                            },
                            {
                              pattern: /^([^0-9]*)$/,
                              message: "Please Insert Valid Bank Name",
                            },
                          ]}
                        >
                          <Input
                            name="bankName"
                            autoComplete="off"
                            placeholder="Bank Name"
                            value={chequeDetails.bankName}
                            onChange={handleChequeDetailsChange}
                          />
                        </Form.Item>
                      </Col>

                      <Col xl={8} lg={12} md={12} sm={24} xs={24}>
                        <Form.Item
                          name="BranchName"
                          label="Branch Name #"
                          className="field-with-prefix"
                          rules={[
                            {
                              required: true,
                              message: "Branch Name is required",
                            },
                            {
                              pattern: /^([^0-9]*)$/,
                              message: "Please Insert Valid Branch Name",
                            },
                          ]}
                        >
                          <Input
                            name="branchName"
                            autoComplete="off"
                            placeholder="Branch Name"
                            value={chequeDetails.branchName}
                            onChange={handleChequeDetailsChange}
                          />
                        </Form.Item>
                      </Col>

                      <Col xl={4} lg={12} md={12} sm={24} xs={24}>
                        <Form.Item
                          name="ChequeNo"
                          label="Cheque/Draft #"
                          className="field-with-prefix"
                          rules={[
                            {
                              required: true,
                              message: "Cheque No. is required",
                            },
                            {
                              pattern: /^(?:\d*)$/,
                              message: "Please Insert Valid Cheque/Draft Id",
                            },
                          ]}
                        >
                          <Input
                            name="chequeNo"
                            autoComplete="off"
                            placeholder="Cheque / Draft"
                            value={chequeDetails.chequeNo}
                            onChange={handleChequeDetailsChange}
                          />
                        </Form.Item>
                      </Col>

                      <Col xl={4} lg={12} md={12} sm={24} xs={24}>
                        <Form.Item
                          name="ChequeDate"
                          label="Date #"
                          className="field-with-prefix"
                          rules={[
                            { required: true, message: "Date is required" },
                          ]}
                        >
                          <DatePicker
                            placeholder="Cheque date"
                            style={{ width: "100%" }}
                            format="YYYY-MM-DD"
                            value={
                              chequeDetails.chequeDate
                                ? moment(chequeDetails.chequeDate, "YYYY-MM-DD")
                                : null
                            }
                            onChange={handleChequeDateChange}
                          />
                        </Form.Item>
                      </Col>

                      <Col xl={6} lg={12} md={12} sm={24} xs={24}>
                        <Form.Item
                          label="Receipt Number"
                          name="receiptNo"
                          rules={[
                            {
                              required: false,
                              message: "Receipt number is required",
                            },
                          ]}
                        >
                          <Input disabled value={currentReceiptNo + 1} />{" "}
                        </Form.Item>
                      </Col>
                      <Col xl={6} lg={12} md={12} sm={24} xs={24}>
                        <Form.Item
                          name="ReceiptDate"
                          label="Receipt Date #"
                          className="field-with-prefix"
                          rules={[
                            { required: true, message: "Date is required" },
                          ]}
                        >
                          <DatePicker
                            placeholder="Receipt date"
                            style={{ width: "100%" }}
                            format="YYYY-MM-DD"
                            value={
                              cashDetails.receiptDate
                                ? moment(cashDetails.receiptDate, "YYYY-MM-DD")
                                : null
                            }
                            onChange={handleCashDateChange}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  )}

                  {/* input for cash */}
                  {paymentMethod === "CASH" && (
                    <Row gutter={10}>
                      <Col xl={6} lg={12} md={12} sm={24} xs={24}>
                        {/* <Form.Item
                          name="ReceiptNo"
                          label="Receipt No #"
                          className="field-with-prefix"
                          rules={[
                            {
                              required: true,
                              message: "Receipt No. is required",
                            },
                            {
                              pattern: /^(?:\d*)$/,
                              message: "Please Insert Valid Receipt No.",
                            },
                          ]}
                        >
                          <Input
                            name="receiptNo"
                            autoComplete="off"
                            placeholder="Receipt No"
                            value={cashDetails.receiptNo}
                            onChange={handleCashDetailsChange}
                          />
                        </Form.Item> */}

                        <Form.Item
                          label="Receipt Number"
                          name="receiptNo"
                          rules={[
                            {
                              required: true,
                              message: "Receipt number is required",
                            },
                          ]}
                        >
                          <Input disabled value={currentReceiptNo + 1} />{" "}
                        </Form.Item>
                      </Col>

                      <Col xl={6} lg={12} md={12} sm={24} xs={24}>
                        <Form.Item
                          name="ReceiptDate"
                          label="Receipt Date #"
                          className="field-with-prefix"
                          rules={[
                            { required: true, message: "Date is required" },
                          ]}
                        >
                          <DatePicker
                            placeholder="Receipt date"
                            style={{ width: "100%" }}
                            format="YYYY-MM-DD"
                            value={
                              cashDetails.receiptDate
                                ? moment(cashDetails.receiptDate, "YYYY-MM-DD")
                                : null
                            }
                            onChange={handleCashDateChange}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  )}
                </Form>
                {/*  payment  end */}
                {/* success */}
                <Row>
                  <Col span={1} style={{ textAlign: "end" }}>
                    <Form.Item className="submit">
                      <Button
                        size="large"
                        className="success-btn"
                        style={{
                          marginTop: "10px",
                          backgroundColor: isSubmitDisabled
                            ? "#d9d9d9"
                            : "#0a0b55",
                          color: isSubmitDisabled ? "#8c8c8c" : "#fff",
                          borderColor: isSubmitDisabled ? "#d9d9d9" : "#52c41a",
                        }}
                        htmlType="submit"
                        onClick={Finalsubmit}
                        disabled={isSubmitDisabled}
                      >
                        Submit
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
          </Col>
        </Row>
      </div>
    </SiteLayout>
  );
}

export default connect(mapState, mapDispatch)(AddFees);
