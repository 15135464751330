import React, { useEffect, useState, useRef } from "react";
import SiteLayout from "../../Component/Reusable/Layout/SiteLayout";
import { connect } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import "../Members/style.css";
import api from "../../Config/Api";
import {
  Button,
  Form,
  Input,
  Row,
  Col,
  Typography,
  message,
  Spin,
  Select,
  DatePicker,
  Descriptions,
  Radio,
} from "antd";
import { AddDfcFees } from "../../Action/DfcFees";
import moment from "moment";

import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
} from "@mui/material";
const { Title } = Typography;
// Action

const mapDispatch = (dispatch) => {
  return {
    TransactioEdit: (payload) => dispatch(TransactioEdit(payload)),
  };
};

const mapState = (props) => ({
  SelectLoader: props?.MemberReducer?.isloading,
  success: props?.MemberReducer?.success,
  successDetails: props?.MemberReducer?.successDetails,
  MemberDetails: props?.MemberReducer?.MemberDetails,
});

function TransactioEdit(store) {
  const formRef = useRef();
  const [form] = Form.useForm();
  const { id } = useParams();
  const navigate = useNavigate();
  const dfcdata = useSelector((state) => state.FeesReducer);

  useEffect(() => {
    if (id) {
      store?.SingalMemberDetail(id);
    }
  }, [id, dfcdata]);

  useEffect(() => {
    form.setFieldsValue({
      PaymentMethod: "CASH",
    });
  }, []);

  useEffect(() => {
    if (store?.success && store?.successDetails?.data?.message) {
      form.resetFields();
      store.resetState();
    }
  }, [store?.success && store?.successDetails?.data?.message]);

  const [members, setMembers] = useState([]);
  const [transactionDetails, setTransactionDetails] = useState(null);
  const txnId = useParams().txnId;
  const [loading, setLoading] = useState(false);
  const [dfcDetails, setDfcDetails] = useState(null);
  const [dfcNumber, setDfcNumber] = useState("");
  const [groupData, setGroupData] = useState("");
  const [userId, setUserId] = useState("");
  const [memberDetails, setMemberDetails] = useState([]);

  const [combinedTotal, setCombinedTotal] = useState(0);
  const [totalFees, setTotalFees] = useState(0);
  const [totalCharges, setTotalCharges] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState("CASH");
  const [cashDetails, setCashDetails] = useState({
    receiptNo: "",
    receiptDate: null,
  });
  const [chequeDetails, setChequeDetails] = useState({
    bankName: "",
    branchName: "",
    chequeNo: "",
    chequeDate: null,
  });

  const handleSubmit = async () => {
    if (!dfcNumber) {
      message.error("DFC number is required");
      return;
    }

    if (!/^\d+$/.test(dfcNumber)) {
      message.error("Please insert a valid DFC number");
      return;
    }

    setLoading(true);

    try {
      const response = await api.get(
        `admin/fetchDfcDetails?dfcNumber=${dfcNumber}`
      );

      if (response.status === 200) {
        setDfcDetails(response.data.data);
        message.success("Data fetched successfully");
      } else {
        message.error(response.data.message || "Failed to fetch details");
      }
    } catch (error) {
      message.error(
        error.response?.data?.message || "Please insert valid Dfc No."
      );
    } finally {
      setLoading(false);
    }
  };

  const updateTotals = (updatedDetails) => {
    let newTotalFees = 0;
    let newTotalCharges = 0;

    updatedDetails.forEach(({ fees, lateFees }) => {
      newTotalFees += fees || 0;
      newTotalCharges += lateFees || 0;
    });

    const newCombinedTotal = newTotalFees + newTotalCharges;

    setTotalFees(newTotalFees);
    setTotalCharges(newTotalCharges);
    setCombinedTotal(newCombinedTotal);
  };

  useEffect(() => {
    const fetchTransactionDetails = async () => {
      if (!txnId) {
        message.error("Transaction ID is undefined");
        return;
      }

      try {
        setLoading(true);
        const response = await api.get(
          `admin/getTransactionDetails?txnId=${txnId}`
        );

        if (response.status === 200) {
          const transactionDetails = response.data;

          form.setFieldsValue({
            // Set internalGroupId from response
            internalGroupId: transactionDetails.paymentDetails.internalGroupId,
            // Map the members array from response to the form
            members: transactionDetails.paymentDetails.members.map(
              (member) => ({
                _id: member._id,
                memberNo: member.memberNo,
                memberName: `${member.firstname} ${member.middlename} ${member.lastname}`, // Include middlename
                city: member.city,
                fees: member.fees,
                lateFees: member.lateFees,
              })
            ),
            // Setting the receiptNo from response
            receiptNo: transactionDetails.paymentDetails.receiptNumber || "", // Default to empty string if undefined
            // Set receiptDate if it exists in response
            receiptDate: transactionDetails.paymentDetails.receiptDate
              ? moment(
                  transactionDetails.paymentDetails.receiptDate,
                  "MM-DD-YYYY"
                )
              : null,
          });

          // Store members in state to allow rendering
          setMembers(transactionDetails.paymentDetails.members || []);
        } else {
          message.error("Failed to fetch transaction details");
        }
      } catch (error) {
        console.error("Error fetching transaction details:", error);
        message.error("An error occurred while fetching transaction details");
      } finally {
        setLoading(false);
      }
    };

    fetchTransactionDetails();
  }, [txnId, form]);

  useEffect(() => {
    const total = members.reduce((acc, member) => {
      const fees = parseFloat(member.fees) || 0;
      const lateFees = parseFloat(member.lateFees) || 0;
      return acc + fees + lateFees;
    }, 0);

    setCombinedTotal(total); // Update combined total
  }, [members]);

  function handleFormSubmit1(params) {
    // pass payload to action and reducer
    let payload = {
      memberdetails: [
        {
          memberId1: params?.MemberId1,
          fees1: params?.Fees1,
          charges1: params?.Charges1,
        },
        {
          memberId2: params?.MemberId2,
          fees2: params?.Fees2,
          charges2: params?.Charges2,
        },
      ],
      payment: params?.PaymentMethod,
      receiptno: params?.ReceiptNo,
      receiptdate: params?.ReceiptDate,
    };

    if (params?.Group) {
      payload["group"] = params?.Group;
    }

    if (params?.FirstMember) {
      payload["firstmember"] = params?.FirstMember;
    }

    if (params?.PaymentMethod === "CHEQUE") {
      payload["chequedetail"] = {
        bank: params?.BankName,
        branch: params?.BranchName,
        cheque: params?.ChequeNo,
        chequedate: params?.ChequeDate,
      };
    }
    store.addFess(payload);
  }

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const fetchGroupData1 = async () => {
    if (!groupData) {
      message.error("Please enter a Group ID");
      return;
    }

    try {
      const response = await api.get("/admin/fetchGroupDetails", {
        params: { internalGroupId: groupData },
      });

      if (response.data.success) {
        const fetchedMembers = response.data.data.members;
        setMembers(fetchedMembers);

        const memberDetailsArray = fetchedMembers.map((member) => ({
          _id: member._id,
          memberId: member._id,
          fees: member.fees || 0,
          lateFees: member.lateFees || 0,
        }));

        setMemberDetails(memberDetailsArray);
        message.success("Data fetched successfully");
      } else {
        message.error("Failed to fetch group data");
      }
    } catch (error) {
      console.error("API error:", error);
      message.error("Failed to fetch group data. Please try again.");
    }
  };

  const handleSubmit1 = async () => {
    await fetchGroupData1();
  };

  const handleCashDetailsChange = (e) => {
    const { name, value } = e.target;
    setCashDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleCashDateChange = (date, dateString) => {
    setCashDetails((prevDetails) => ({
      ...prevDetails,
      receiptDate: dateString,
    }));
  };

  const handleChequeDetailsChange = (e) => {
    const { name, value } = e.target;
    setChequeDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleChequeDateChange = (date, dateString) => {
    setChequeDetails((prevDetails) => ({
      ...prevDetails,
      chequeDate: dateString,
    }));
  };

  const handleInputChange = (index, fieldType) => (e) => {
    const value = parseFloat(e.target.value) || 0;

    setMemberDetails((prevDetails) => {
      const updatedDetails = [...prevDetails];
      updatedDetails[index] = {
        ...updatedDetails[index],
        [fieldType]: value,
      };

      // Ensure _id is retained for each member
      // if (!updatedDetails[index]._id) {
      //   updatedDetails[index]._id = prevDetails[index]._id;
      // }

      updateTotals(updatedDetails); // Function to recalculate totals
      return updatedDetails;
    });
  };

  const handleEditSubmit = async () => {
    try {
      const payload = {
        txnId: txnId,
        groupId: groupData,
        amount: combinedTotal,
        paymentMethod: paymentMethod,
        userId: userId,
        dfcNumber: dfcNumber,
        membersFees: memberDetails, // memberDetails must include _id
        receiptNumber: cashDetails.receiptNo,
        receiptDate: cashDetails.receiptDate,
        ...(paymentMethod === "CASH" && { cashDetails }),
        ...(paymentMethod === "CHEQUE" && { chequeDetails }),
      };

      const response = await api.put(
        `admin/editTransaction?txnId=${txnId}`,
        payload
      );

      if (response.status === 200) {
        message.success("Transaction updated successfully");
        navigate("/transactions");
      } else {
        message.error(`Failed to submit: ${response.data.message}`);
      }
    } catch (error) {
      message.error(
        `An error occurred while submitting the transaction details. ${error.message}`
      );
    }
  };

  if (loading) {
    return <Spin tip="Loading..." />;
  }

  return (
    <SiteLayout>
      <div className="headingDiv">
        <h2 style={{ textAlign: "center", margin: "0px" }}>Edit DFC Fees</h2>
      </div>

      <div className="subscription-container center-content">
        <Row gutter={2}>
          <Col
            xxl={20}
            xl={20}
            lg={24}
            md={24}
            sm={24}
            xs={24}
            style={{ margin: "0 auto" }}
          >
            <div
              style={{ padding: "30px" }}
              className="checkout-form-container gig-sure-card-wrapper"
            >
              <Form
                scrollToFirstError={true}
                onFinish={handleFormSubmit1}
                onFinishFailed={onFinishFailed}
                layout="vertical"
                form={form}
                name="dfc"
                autoComplete="off"
                ref={formRef}
              ></Form>
              <Box component="form" sx={{ mt: 3 }}>
                <Row gutter={10}>
                  <Col xl={8} lg={8} md={10} sm={24} xs={24}>
                    <Row gutter={10} align="middle">
                      <Col span={16}>
                        <Form.Item
                          name="DFC Number"
                          label="DFC Number"
                          rules={[
                            {
                              pattern: /^(?:\d*)$/,
                              message: "Please insert a valid Group ID",
                            },
                          ]}
                        >
                          <Input
                            className="form-input-border"
                            autoComplete="off"
                            placeholder="DFC Number"
                            value={dfcNumber}
                            onChange={(e) => setDfcNumber(e.target.value)}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Button
                          // type="primary"
                          variant="contained"
                          color="primary"
                          className="btn"
                          disabled={loading}
                          onClick={handleSubmit}
                          sx={{ width: "100%", py: 1, height: "40px" }}
                        >
                          {loading ? <CircularProgress size={24} /> : "Search"}
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                {dfcDetails && (
                  <Box mt={1}>
                    <Typography variant="h6" mb={2}>
                      DFC Details:
                    </Typography>
                    {dfcDetails.length > 0 ? (
                      <TableContainer component={Paper}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Member No</TableCell>
                              <TableCell>Member Name</TableCell>
                              <TableCell>Date of Death</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {dfcDetails.map((detail, index) => (
                              <TableRow key={index}>
                                <TableCell>{detail.memberNo}</TableCell>
                                <TableCell>{detail.memberName}</TableCell>
                                <TableCell>
                                  {new Date(
                                    detail.dateOfDeath
                                  ).toLocaleDateString()}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    ) : (
                      <Typography variant="body2">
                        No details available for this DFC number.
                      </Typography>
                    )}
                  </Box>
                )}
              </Box>

              <Form form={form} layout="vertical">
                <Row gutter={10}>
                  <Col xl={8} lg={8} md={10} sm={24} xs={24}>
                    <Row gutter={10} align="middle">
                      <Col span={16}>
                        <Form.Item
                          label="Group #"
                          name="internalGroupId"
                          rules={[
                            {
                              pattern: /^(?:\d*)$/,
                              message: "Please insert a valid Group ID",
                            },
                          ]}
                        >
                          <Input
                            className="form-input-border"
                            autoComplete="off"
                            placeholder="Group ID"
                            value={groupData} // Keep track of the Group ID input
                            onChange={(e) => setGroupData(e.target.value)} // Update the Group ID state
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Button
                          variant="contained"
                          color="primary"
                          className="btn"
                          onClick={handleSubmit1}
                          sx={{ width: "100%", py: 1, height: "40px" }}
                        >
                          Search
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                {(members.length > 0
                  ? members
                  : transactionDetails?.paymentDetails?.members || []
                ).map((member, index) => (
                  <Row gutter={10} key={index}>
                    {/* Member ID */}
                    <Col xl={4} lg={6} md={8} sm={24} xs={24}>
                      <Form.Item
                        label="Member Id #"
                        className="field-with-prefix"
                      >
                        <Input
                          placeholder="Member Id"
                          disabled={true}
                          style={{ color: "#808080" }}
                          value={member.memberNo}
                        />
                      </Form.Item>
                    </Col>

                    {/* Member Name */}
                    <Col xl={8} lg={12} md={16} sm={24} xs={24}>
                      <Form.Item label="Member #" className="field-with-prefix">
                        <Input
                          placeholder="Member"
                          disabled={true}
                          style={{ color: "#808080" }}
                          value={member.memberName}
                        />
                      </Form.Item>
                    </Col>

                    {/* City */}
                    <Col xl={4} lg={6} md={8} sm={24} xs={24}>
                      <Form.Item label="City #" className="field-with-prefix">
                        <Input
                          placeholder="City"
                          disabled={true}
                          style={{ color: "#808080" }}
                          value={member.city}
                        />
                      </Form.Item>
                    </Col>

                    {/* Fees */}
                    <Col xl={4} lg={12} md={8} sm={24} xs={24}>
                      <Form.Item
                        label="Fees #"
                        className="field-with-prefix"
                        name={["members", index, "fees"]}
                        initialValue={member.fees}
                        rules={[
                          {
                            required: true,
                            message: "Fees are required",
                          },
                          {
                            pattern: /^[0-9]+$/,
                            message: "Please insert valid Fees",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Fees"
                          onChange={handleInputChange(index, "fees")} // Pass 'fees' to the handler
                        />
                      </Form.Item>
                    </Col>

                    {/* Late Fees */}
                    <Col xl={4} lg={12} md={8} sm={24} xs={24}>
                      <Form.Item
                        label="Late Fees"
                        className="field-with-prefix"
                        name={["members", index, "lateFees"]} // Bind to form value
                        initialValue={member.lateFees} // Ensure initial value is set
                        rules={[
                          {
                            required: true,
                            message: "Late Fees are required",
                          },
                          {
                            pattern: /^[0-9]+$/,
                            message: "Please insert valid Late Fees",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Late Fees"
                          onChange={handleInputChange(index, "lateFees")} // Pass 'charges' to the handler
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                ))}

                <Col xl={4} lg={6} md={8} sm={24} xs={24}>
                  <Form.Item label="Combined Total">
                    <Input
                      value={combinedTotal}
                      disabled={true}
                      style={{ color: "#808080" }}
                    />
                  </Form.Item>
                </Col>
              </Form>

              <Form layout="vertical">
                {/* radio button */}
                <Row className="mt-1">
                  <Col span={24}>
                    <Title level={4}>Payment</Title>
                  </Col>
                </Row>
                <Form.Item
                  name="PaymentMethod"
                  label="Payment Method #"
                  initialValue={paymentMethod}
                >
                  <Radio.Group
                    value={paymentMethod}
                    onChange={(e) => setPaymentMethod(e.target.value)}
                  >
                    <Radio value="CASH">Cash</Radio>
                    <Radio value="CHEQUE">Cheque</Radio>
                  </Radio.Group>
                </Form.Item>

                {/* input for cheque */}
                {paymentMethod === "CHEQUE" && (
                  <Row gutter={10}>
                    <Col xl={8} lg={12} md={12} sm={24} xs={24}>
                      <Form.Item
                        name="bankName"
                        label="Bank Name #"
                        className="field-with-prefix"
                        rules={[
                          {
                            required: true,
                            message: "Bank Name is required",
                          },
                          {
                            pattern: /^([^0-9]*)$/,
                            message: "Please Insert Valid Bank Name",
                          },
                        ]}
                      >
                        <Input
                          name="bankName"
                          autoComplete="off"
                          placeholder="Bank Name"
                          value={chequeDetails.bankName}
                          onChange={handleChequeDetailsChange}
                        />
                      </Form.Item>
                    </Col>

                    <Col xl={8} lg={12} md={12} sm={24} xs={24}>
                      <Form.Item
                        name="branchName"
                        label="Branch Name #"
                        className="field-with-prefix"
                        rules={[
                          {
                            required: true,
                            message: "Branch Name is required",
                          },
                          {
                            pattern: /^([^0-9]*)$/,
                            message: "Please Insert Valid Branch Name",
                          },
                        ]}
                      >
                        <Input
                          name="branchName"
                          autoComplete="off"
                          placeholder="Branch Name"
                          value={chequeDetails.branchName}
                          onChange={handleChequeDetailsChange}
                        />
                      </Form.Item>
                    </Col>

                    <Col xl={4} lg={12} md={12} sm={24} xs={24}>
                      <Form.Item
                        name="chequeNo"
                        label="Cheque/Draft #"
                        className="field-with-prefix"
                        rules={[
                          {
                            required: true,
                            message: "Cheque No. is required",
                          },
                          {
                            pattern: /^(?:\d*)$/,
                            message: "Please Insert Valid Cheque/Draft Id",
                          },
                        ]}
                      >
                        <Input
                          name="chequeNo"
                          autoComplete="off"
                          placeholder="Cheque / Draft"
                          value={chequeDetails.chequeNo}
                          onChange={handleChequeDetailsChange}
                        />
                      </Form.Item>
                    </Col>

                    <Col xl={4} lg={12} md={12} sm={24} xs={24}>
                      <Form.Item
                        name="chequeDate"
                        label="Date #"
                        className="field-with-prefix"
                        rules={[
                          { required: true, message: "Date is required" },
                        ]}
                      >
                        <DatePicker
                          placeholder="Cheque date"
                          style={{ width: "100%" }}
                          format="YYYY-MM-DD"
                          value={
                            chequeDetails.chequeDate
                              ? moment(chequeDetails.chequeDate, "YYYY-MM-DD")
                              : null
                          }
                          onChange={handleChequeDateChange}
                        />
                      </Form.Item>
                    </Col>
                    <Col xl={6} lg={12} md={12} sm={24} xs={24}>
                      <Form.Item
                        label="Receipt Number"
                        name="receiptNo"
                        className="field-with-prefix"
                      >
                        <p
                          style={{
                            margin: 0,
                            height: "47px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            fontSize: "17px",
                            float: "left",
                          }}
                        >
                          {form.getFieldValue("receiptNo") || "N/A"}
                        </p>
                      </Form.Item>
                    </Col>
                    <Col xl={6} lg={12} md={12} sm={24} xs={24}>
                      <Form.Item
                        name="receiptDate"
                        label="Receipt Date #"
                        className="field-with-prefix"
                        rules={[
                          { required: true, message: "Date is required" },
                        ]}
                      >
                        <DatePicker
                          placeholder="Receipt date"
                          style={{ width: "100%" }}
                          format="YYYY-MM-DD"
                          value={form.getFieldValue("receiptNo") || "N/A"}
                          onChange={handleCashDateChange}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                )}

                {/* input for cash */}
                {paymentMethod === "CASH" && (
                  <Row gutter={10}>
                    <Col xl={6} lg={12} md={12} sm={24} xs={24}>
                      <Form.Item
                        label="Receipt Number"
                        name="receiptNo"
                        className="field-with-prefix"
                      >
                        <p
                          style={{
                            margin: 0,
                            height: "45px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            fontSize: "17px",
                          }}
                        >
                          {form.getFieldValue("receiptNo") || "N/A"}
                        </p>
                      </Form.Item>

                      {/* <Form.Item label="Receipt Date" name="receiptDate">
          <span>
            {form.getFieldValue("receiptDate")
              ? form.getFieldValue("receiptDate").format("YYYY-MM-DD")
              : "N/A"}
          </span>
        </Form.Item> */}
                    </Col>

                    <Col xl={6} lg={12} md={12} sm={24} xs={24}>
                      <Form.Item
                        name="receiptDate"
                        label="Receipt Date #"
                        className="field-with-prefix"
                        rules={[
                          { required: true, message: "Date is required" },
                        ]}
                      >
                        <DatePicker
                          placeholder="Receipt date"
                          style={{ width: "100%" }}
                          format="YYYY-MM-DD"
                          value={
                            cashDetails.receiptDate
                              ? moment(cashDetails.receiptDate, "YYYY-MM-DD")
                              : null
                          }
                          onChange={handleCashDateChange}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                )}

                <div style={{ textAlign: "left", marginTop: "20px" }}>
                  <Button type="primary" onClick={handleEditSubmit}>
                    Update Transaction
                  </Button>
                </div>
              </Form>
            </div>
          </Col>
        </Row>
      </div>
    </SiteLayout>
  );
}

export default connect(mapState, mapDispatch)(TransactioEdit);
